export const MessageTypes = {
    SUCCESS: 'success',
    ERROR: 'error',
};

export default ({ theme, type }) => ({
    display: 'block',
    fontSize: '1rem',
    color: theme.colors.white,
    textAlign: 'left',
    height: '100%',
    padding: '0.5rem 1rem 0.5rem 3rem',
    position: 'relative',
    backgroundColor: theme.colors.greenDark,
    borderRadius: 4,
    lineHeight: '2.25',
    ...(type !== MessageTypes.SUCCESS && {
        fontWeight: 400,
    }),
    ...(type === MessageTypes.ERROR && {
        fontWeight: 500,
        lineHeight: '1.75',
    }),
    min992px: {
        max1390px: {
            lineHeight: '1.15',
        },
    },
    '& img': {
        display: 'block',
        float: 'left',
        position: 'absolute',
        top: '1rem',
        left: '0.75rem',
    },
});
