import React from 'react';
import { combineRules } from 'fela';
import { FelaComponent } from 'react-fela';
import PropTypes from 'prop-types';

import style from './Section.style';

const Section = ({ customStyle, ...props }) => (
    <FelaComponent style={combineRules(style, customStyle)}>
        {({ className }) => <section {...props} className={className} />}
    </FelaComponent>
);

Section.proTypes = {
    customStyle: PropTypes.func,
};

Section.defaultProps = {
    customStyle: () => {},
};

export default Section;
