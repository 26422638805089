export const spinner = ({ animationName }) => ({
    display: 'flex',
    animation: `1000ms ${animationName} infinite linear`,
});

export const spin = () => ({
    '0%': {
        transform: 'rotate(0deg)',
    },
    '100%': {
        transform: 'rotate(360deg)',
    },
});
