import { label as labelOriginal } from '../../Label/Label.style';

export const label = ({ theme }) => ({
    ...labelOriginal({ theme }),
    lineHeight: '1rem',
    marginBottom: '0.35rem',
    marginTop: '1.75rem',
});

const linkActiveStyle = {
    paddingBottom: 0,
    textDecoration: 'underline',
};

export const wrapper = ({ theme }) => ({
    backgroundColor: theme.colors.green,
    textAlign: 'center',
    paddingTop: '3rem',

    '& .Heading2': {
        maxWidth: '35rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '3.2rem',
        textAlign: 'left',
        lineHeight: 1.25,
        fontSize: '2rem',
        padding: 0,
    },
    '& .Text': {
        paddingLeft: 0,
    },
    '& .Text .Link': {
        textAlign: 'left',
        padding: 0,
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '3rem',
        textDecoration: 'underline',
        textTransform: 'none',
        marginBottom: '1rem',
        display: 'block',

        color: theme.colors.white,
        border: 'none',

        ':active': linkActiveStyle,
        ':focus': linkActiveStyle,
        ':hover': linkActiveStyle,

        min768px: {
            fontSize: '2rem',
        },
    },
    '& .Text img': {
        display: 'block',
        marginBottom: '2rem',

        min768px: {
            display: 'inline-block',
            float: 'left',
        },
    },
    '& .ant-row': {
        maxWidth: '36rem',
        margin: 'auto',
        max768px: {
            maxWidth: '100%',
            paddingLeft: '1.25rem',
            paddingRight: '1.25rem',
        },
    },

    min992px: {
        '& .Text': {
            marginBottom: 0,
        },
        '& .ant-row': {
            maxWidth: '68rem',
            margin: 'auto',
        },
    },
});

export const feedback = () => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '3.25rem',
    marginBottom: '4.25rem',
    '& img': {
        maxWidth: 62,
        display: 'block',
    },
});

export const personWrapper = ({ theme }) => ({
    paddingLeft: '2rem',
    textAlign: 'left',
    color: theme.colors.white,
});

export const person = ({ theme }) => ({
    color: theme.colors.white,
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: 1.25,
});

export const personText = () => ({
    fontSize: '1.25rem',
});
