import React from 'react';
import { FelaComponent } from 'react-fela';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Row, Col } from 'antd/lib/grid';

import config from '../../../../config';

import Button from '../../../Button';
import Message from '../../../Message';

import style from './ContactForm.style';
import LoadingSpinner from './LoadingSpinner';
import ContactFormControl from './ContactFormControl';
import { InputTypes } from '../../../Input/Input';
import submitContactForm from './utils/submitContactForm';

const fields = {
    name: 'name',
    email: 'email',
    message: 'message',
    checkbox: 'checkbox',
};

const validationSchema = Yup.object().shape({
    [fields.name]: Yup.string()
        .min(2)
        .max(60)
        .required(true),
    [fields.email]: Yup.string()
        .email()
        .required(true),
    [fields.message]: Yup.string()
        .max(2000)
        .required(true),
    [fields.checkbox]: Yup.bool().oneOf([true]),
});

const initialValues = {
    [fields.name]: '',
    [fields.email]: '',
    [fields.message]: '',
    [fields.checkbox]: false,
};

const checkboxLabelExtend = ({ theme }) => ({
    label: {
        fontFamily: theme.fontFamily,
        fontWeight: 500,
        letterSpacing: 0,
        '& a': {
            textDecoration: 'underline',
            color: theme.colors.white,
            borderBottom: 'none',
            '&:hover': {
                textDecoration: 'none',
            },
        },
    },
});

const ContactForm = () => {
    const intl = useIntl();

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitContactForm}>
            {props => {
                const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, status } = props;
                const errorsAndTouches = Object.keys(fields).reduce((acc, key) => {
                    acc[key] = Boolean(touched[key] && errors[key]);
                    return acc;
                }, {});
                return (
                    <FelaComponent style={style}>
                        {({ className }) => (
                            <form
                                action={config.formSubmitEndpoint}
                                method="POST"
                                onSubmit={handleSubmit}
                                className={className}
                            >
                                <Row>
                                    <Col xs={24} md={11}>
                                        <ContactFormControl
                                            value={values.name}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            hasError={errorsAndTouches.name}
                                            id={fields.name}
                                        />
                                    </Col>
                                    <Col xs={24} md={{ span: 11, offset: 2 }}>
                                        <ContactFormControl
                                            value={values.email}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            hasError={errorsAndTouches.email}
                                            id={fields.email}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24}>
                                        <ContactFormControl
                                            value={values.message}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            hasError={errorsAndTouches.message}
                                            id={fields.message}
                                            type={InputTypes.TEXTAREA}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24}>
                                        <ContactFormControl
                                            value={values.checkbox}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            hasError={errorsAndTouches.checkbox}
                                            id={fields.checkbox}
                                            type={InputTypes.CHECKBOX}
                                            labelExtend={checkboxLabelExtend}
                                        />
                                    </Col>
                                </Row>
                                <Row type={'flex'} justify="start" className={'row-submit'}>
                                    <Col xs={24} sm={7}>
                                        <Button type="submit">
                                            {isSubmitting && <LoadingSpinner />}
                                            {!isSubmitting && intl.formatMessage({ id: 'event.contacts.form.submit' })}
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={{ span: 16, offset: 1 }}>
                                        {status && status.success && (
                                            <Message>
                                                <FormattedHTMLMessage tagName="span" id="event.contacts.form.success" />
                                            </Message>
                                        )}
                                        {status && status.error && (
                                            <Message type="error">
                                                <FormattedHTMLMessage tagName="span" id="event.contacts.form.error" />
                                            </Message>
                                        )}
                                    </Col>
                                </Row>
                            </form>
                        )}
                    </FelaComponent>
                );
            }}
        </Formik>
    );
};

export default ContactForm;
