import React from 'react';
import PropTypes from 'prop-types';

import { FelaComponent, connect as connectFela } from 'react-fela';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';

import { Row, Col } from 'antd/lib/grid';

import Text from '../../Text';
import Link from '../../Link';

import Section from '../Section';
import ContactForm from './ContactForm';

import * as componentStyles from './ContactsSection.style';
import Heading2 from '../../Heading2';

// import personAckee from '../../../images/faces/img_face_ackee.png';
import { compose } from 'recompose';

const ContactsSection = ({ titleId, intl, styles }) => (
    <FelaComponent style={componentStyles.wrapper}>
        <Section id="contacts">
            <Row>
                <Col xs={24} lg={12} className={'Left'}>
                    <Heading2 color="white" element="h2">
                        <FormattedHTMLMessage tagName="span" id={titleId} />
                    </Heading2>
                    <Text color="lightBlack">
                        <span className={styles.label}>
                            <FormattedMessage id="contacts.email.text" />
                        </span>
                        <Link to={`mailto:${intl.formatMessage({ id: 'contacts.email.address' })}`} type="native">
                            <FormattedMessage id="contacts.email.address" />
                        </Link>
                    </Text>
                    {/*
                      * Uncomment when there will be a new Account Manager
                    <FelaComponent style={componentStyles.feedback}>
                        <img src={personAckee} alt={intl.formatMessage({ id: 'conference.feedback.author' })} />
                        <FelaComponent style={componentStyles.personWrapper}>
                            <FelaComponent style={componentStyles.person}>
                                {intl.formatMessage({ id: 'conference.feedback.author' })}
                            </FelaComponent>
                            <FelaComponent style={componentStyles.personText}>
                                {intl.formatMessage({ id: 'conference.feedback.text' })}
                            </FelaComponent>
                        </FelaComponent>
                    </FelaComponent>
                    */}
                </Col>
                <Col xs={24} lg={12}>
                    <ContactForm />
                </Col>
            </Row>
        </Section>
    </FelaComponent>
);

ContactsSection.propTypes = {
    titleId: PropTypes.string.isRequired,
};

export default compose(injectIntl, connectFela(componentStyles))(ContactsSection);
