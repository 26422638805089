import React from 'react';
import { FelaComponent } from 'react-fela';
import * as componentStyles from './LoadingSpinner.style.js';
import spinner from '../../../../images/spinner.svg';
import FelaKeyFrame from '../FelaKeyFrame';

const LoadingSpinner = () => (
    <FelaKeyFrame keyframe={componentStyles.spin}>
        {animationName => (
            <FelaComponent style={componentStyles.spinner} animationName={animationName}>
                {({ className }) => <img className={className} src={spinner} alt="" />}
            </FelaComponent>
        )}
    </FelaKeyFrame>
);

export default LoadingSpinner;
