import axios from 'axios';
import config from '../../../../../config';

const submitContactForm = async (values, { setSubmitting, setStatus, resetForm }, locale) => {
    setSubmitting(true);
    setStatus({
        success: false,
        error: false,
    });

    delete values.checkbox; // checkbox is not supported on API
    try {
        await axios.post(config.formSubmitEndpoint, { ...values, locale: locale || 'en' });
        resetForm();
        setStatus({
            success: true,
        });
    } catch (e) {
        setStatus({
            error: true,
        });
    } finally {
        setSubmitting(false);
    }
};

export default submitContactForm;
