export default () => ({
    min992px: {
        paddingLeft: '2rem',
        paddingRight: '2rem',
        maxWidth: '70rem',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '5rem',
    paddingBottom: '9rem',
    min1390px: {
        maxWidth: '90rem',
    },
    maxWidth: '100%',
});
