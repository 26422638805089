import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';

import style, { MessageTypes } from './Message.style';
import iconSuccess from '../../images/iconSuccess.svg';

const Message = ({ children, type, ...props }) => (
    <FelaComponent type={type} style={style}>
        {({ className }) => (
            <div {...props} className={`Message Message-${type} ${props.className} ${className}`}>
                {type === MessageTypes.SUCCESS && <img src={iconSuccess} alt="" />}
                {/*type === MessageTypes.ERROR && <img src={TODO} alt="" />*/}
                {children}
            </div>
        )}
    </FelaComponent>
);

Message.propTypes = {
    type: PropTypes.oneOf(Object.values(MessageTypes)),
    className: PropTypes.string,
    children: PropTypes.node,
};

Message.defaultProps = {
    type: MessageTypes.SUCCESS,
    className: '',
};

export default Message;
