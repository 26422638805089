const sendButtonActive = {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
};

export default ({ theme }) => ({
    min992px: {
        padding: '0 0 0 2rem',
    },

    overflow: 'hidden',

    '& .Label': {
        textTransform: 'uppercase',
        fontSize: '0.85rem',
        fontWeight: 700,
        lineHeight: 2.5,
        textAlign: 'left',
        padding: 0,
        userSelect: 'none',
        '&.checkbox': {
            color: theme.colors.white,
            textTransform: 'none',
            fontSize: '0.85rem',
            marginTop: '1rem',
            lineHeight: '1.5rem',
            marginBottom: '0.75rem',
        },
    },

    '& .Button': {
        float: 'left',
        borderRadius: '6px',
        padding: '0 1.25rem',
        height: '3rem',
        fontWeight: 600,
        fontSize: '1.0625rem',
        lineHeight: '2.5rem',
        transition: 'width,background-color 0.3s ease',
        backgroundColor: 'transparent',
        border: `3px solid ${theme.colors.white}`,
        color: theme.colors.white,
        '&:hover': sendButtonActive,
        '&:active': sendButtonActive,
        '&:focus': sendButtonActive,
    },

    '& .row-submit': {
        marginTop: '1.75rem',
    },

    '& .ant-row': {
        max768px: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },

    '& .ant-row checkbox': {
        flex: 1,
    },

    '& .Message a': {
        color: theme.colors.white,
    },
});
