import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../../Label';
import { FormattedMessage } from 'react-intl';
import Input from '../../../Input';
import Text from '../../../Text';
import { InputTypes } from '../../../Input/Input';
import * as componentStyles from './ContactFormControl.style';
import LocalizedLink from '../../../LocalizedLink';
import { LinkType } from '../../../Link/Link.style';

const ContactFormControl = ({ id, value, onChange, onBlur, hasError, type, labelExtend }) => {
    const controlGroup = [
        <Label key={'label'} htmlFor={id} extend={labelExtend}>
            <FormattedMessage
                id={`event.contacts.form.${id}`}
                values={{
                    a: (...chunks) => (
                        <LocalizedLink forceLocale="cs" type={LinkType.NATIVE} target="_blank" to={'/privacyPolicy'}>
                            {chunks}
                        </LocalizedLink>
                    ),
                }}
            />
        </Label>,
        <Input
            key={'input'}
            id={id}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            hasError={hasError}
            type={type}
        />,
    ];

    if (type === InputTypes.CHECKBOX) {
        controlGroup.reverse();
    }

    if (hasError) {
        controlGroup.push(
            <Text key="error" overrideStyles={componentStyles.errorMessage}>
                <FormattedMessage id={`event.contacts.form.error.${id}`} />
            </Text>,
        );
    }

    return <>{controlGroup}</>;
};

ContactFormControl.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    hasError: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(Object.values(InputTypes)),
    labelExtend: PropTypes.func.isRequired,
};

ContactFormControl.defaultProps = {
    type: InputTypes.INPUT,
    labelExtend: () => {},
};

export default ContactFormControl;
