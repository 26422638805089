export const errorMessage = ({ theme }) => ({
    color: theme.colors.darkRed,
    textAlign: 'left',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: '1rem',
    marginTop: '-0.75rem',
    fontSize: '0.85rem',
    fontWeight: 500,
    lineHeight: 1.65,
});
